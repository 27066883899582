exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-login-tsx": () => import("./../../../src/pages/admin/login.tsx" /* webpackChunkName: "component---src-pages-admin-login-tsx" */),
  "component---src-pages-admin-poems-edit-tsx": () => import("./../../../src/pages/admin/poems/edit.tsx" /* webpackChunkName: "component---src-pages-admin-poems-edit-tsx" */),
  "component---src-pages-admin-poems-index-tsx": () => import("./../../../src/pages/admin/poems/index.tsx" /* webpackChunkName: "component---src-pages-admin-poems-index-tsx" */),
  "component---src-pages-admin-poems-new-tsx": () => import("./../../../src/pages/admin/poems/new.tsx" /* webpackChunkName: "component---src-pages-admin-poems-new-tsx" */),
  "component---src-pages-admin-update-tsx": () => import("./../../../src/pages/admin/update.tsx" /* webpackChunkName: "component---src-pages-admin-update-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */)
}

